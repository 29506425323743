const en = {
    "App.caption": "Cannawayz",

    "WelcomePage.service.GRAPHQL-SERVICE.description": "Frontend dispatcher",
    "WelcomePage.service.AUTH-SERVICE.description": "Authentication service",
    "WelcomePage.service.LOCATION-SERVICE.description": "Geolocation service",
    "WelcomePage.service.VENDOR-SERVICE.description": "Shops and Brands service",
    "WelcomePage.service.USER-SERVICE.description": "User service",

    "UserListPage.role.cannawayz:accidents:view": "Accidents:View",
    "UserListPage.role.cannawayz:accidents:admin": "Accidents:Admin",
    "UserListPage.role.cannawayz:brands:view": "Brands:View",
    "UserListPage.role.cannawayz:brands:admin": "Brands:Admin",
    "UserListPage.role.cannawayz:shops:view": "Shops:View",
    "UserListPage.role.cannawayz:shops:admin": "Shops:Admin",
    "UserListPage.role.cannawayz:users:view": "Users:View",
    "UserListPage.role.cannawayz:users:admin": "Users:Admin",
    "UserListPage.role.cannawayz:super:admin": "Admin",
};
export default en;
