import useLocalStorage from "../hooks/useLocalStorage";
import {createContext} from "react";
import {EuiProvider} from "@elastic/eui";

export const ColorModeContext = createContext({});

/**
 * Light and Dark themes support.
 */
export default function ColorModeProvider({children}) {
    const [colorMode, setColorMode] = useLocalStorage("theme", "light");
    const value = {
        colorMode,
        setColorMode,
    };
    return (
        <ColorModeContext.Provider value={value}>
            {/*
            <Helmet>
                <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/eui_theme_${colorMode}.min.css`} />
            </Helmet>
*/}
            <EuiProvider colorMode={colorMode}>{children}</EuiProvider>
        </ColorModeContext.Provider>
    );
}
