import "./App.css";
import { createBrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router";
import routes from "./routes";
import useAppContext from "./providers/AppProvider";
import LoginPage from "./pages/login/LoginPage";

/**
 * Application entrypoint.
 */
export default function App() {
    const { authenticated, hasRole } = useAppContext();
    if (!authenticated) {
        return <LoginPage />;
    }

    // Filter unavailable routes
    const filtered = [
        {
            ...routes[0],
            children: routes[0].children.filter(route => !!!route.roles || hasRole(...route.roles))
        }
    ];
    const router = createBrowserRouter(filtered, {
        basename: process.env.PUBLIC_URL,
    });

    return <RouterProvider router={router} />;
}
