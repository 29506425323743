const ru = {
    "App.caption": "Cannawayz",

    "App.header.ads.caption": "Реклама",
    "App.header.users.caption": "Пользователи",
    "App.header.brands.caption": "Бренды",
    "App.header.shops.caption": "Магазины",
    "App.header.products.caption": "Продукты",

    "ProfileMenu.logout.caption": "Выйти",
    "ProfileMenu.logout.title": "Завершить сеанс и выйти",
    "ProfileMenu.logoutAll.caption": "Выйти везде",
    "ProfileMenu.logoutAll.title": "Завершить сеанс на всех устройствах",

    "ColorModeSwitcher.light.title": "Переключиться в светлый режим",
    "ColorModeSwitcher.dark.title": "Переключиться в темный режим",

    "AdminFilterButton.caption": "Админы",
    "AdminFilterButton.title": "Переключить поиск администраторов",

    "FuzzyFilterButton.caption": "Нечеткий поиск",
    "FuzzyFilterButton.title": "Включить/выключить нечеткий поиск",

    "PublishedFilterButton.published": "Опубликован",
    "PublishedFilterButton.unpublished": "Не опубликован",
    "PublishedFilterButton.yes": "Да",
    "PublishedFilterButton.no": "Нет",
    "PublishedFilterButton.all": "Все",

    "WithAdminsFilterButton.withAdmins": "Есть админы",
    "WithAdminsFilterButton.withNoAdmins": "Без админов",
    "WithAdminsFilterButton.yes": "Да",
    "WithAdminsFilterButton.no": "Нет",
    "WithAdminsFilterButton.all": "Все",

    "LocationsFilterButton.clear.caption": "Очистить",
    "LocationsFilterButton.countries.loadingMessage": "Загрузка стран",
    "LocationsFilterButton.countries.noMatchesMessage": "Нет совпадений",
    "LocationsFilterButton.countries.emptyMessage": "Нет стран",
    "LocationsFilterButton.countries.placeholder": "Поиск стран",

    "LocationsFilterButton.regions.loadingMessage": "Загрузка регионов",
    "LocationsFilterButton.regions.noMatchesMessage": "Нет совпадений",
    "LocationsFilterButton.regions.emptyMessage": "Нет регионов",
    "LocationsFilterButton.regions.placeholder": "Поиск регионов",

    "LocationsFilterButton.cities.loadingMessage": "Загрузка городов",
    "LocationsFilterButton.cities.noMatchesMessage": "Нет совпадений",
    "LocationsFilterButton.cities.emptyMessage": "Нет городов",
    "LocationsFilterButton.cities.placeholder": "Поиск городов",

    "LocationsFilterButton.locations": "Города и страны",
    "LocationsFilterButton.all": "Все",

    "RetailerServicesFilterButton.caption": "Сервисы",
    "RetailerServicesFilterButton.all": "Все",
    "RetailerServicesFilterButton.storefront": "Storefront",
    "RetailerServicesFilterButton.doctor": "Doctor",
    "RetailerServicesFilterButton.delivery": "Delivery",
    "RetailerServicesFilterButton.smoke": "Smoke shop",
    "RetailerServicesFilterButton.cbd": "CBD shop",

    "toasts.info.title": "Информация",
    "toasts.success.title": "Успешно",
    "toasts.warning.title": "Внимание",
    "toasts.error.title": "Ошибка",

    "LoginPage.caption": "Авторизация",
    "LoginPage.email.caption": "E-mail",
    "LoginPage.email.required.message": "Необходимо ввести e-mail",
    "LoginPage.password.caption": "Пароль",
    "LoginPage.password.required.message": "Необходимо ввести пароль",
    "LoginPage.button.caption": "Войти",

    "ErrorPage.code": "Упс",
    "ErrorPage.title": "Что-то пошло не так",
    "ErrorPage.description": "Случилась ошибочная ошибка и требуется перезагрузить страницу.",
    "ErrorPage.backButton.caption": "Назад",

    "WelcomePage.services.title": "Службы",
    "WelcomePage.services.description": "Состояние служб сервера",
    "WelcomePage.services.id": "Идентификатор экземпляра",
    "WelcomePage.services.status": "Состояние",
    "WelcomePage.services.node": "Хост",
    "WelcomePage.services.address": "Адрес",
    "WelcomePage.services.port": "Порт",
    "WelcomePage.services.started": "Запущен",
    "WelcomePage.services.registered": "Зарегистрирован",
    "WelcomePage.services.lastRenewal": "Последняя проверка",
    "WelcomePage.instances.expand": "Развернуть",
    "WelcomePage.instances.hide": "Скрыть",
    "WelcomePage.service.GRAPHQL-SERVICE.description": "Диспетчер запросов",
    "WelcomePage.service.AUTH-SERVICE.description": "Сервис авторизации",
    "WelcomePage.service.LOCATION-SERVICE.description": "Сервис геолокации",
    "WelcomePage.service.VENDOR-SERVICE.description": "Сервис магазинов и брендов",
    "WelcomePage.service.USER-SERVICE.description": "Сервис пользователей",

    "NotFoundErrorPage.title": "Страница не найдена",
    "NotFoundErrorPage.description": "Запрашиваемая страница не найдена, удалена или временно недоступна.",

    "PreventNavigationDialog.title": "Вы действительно хотите покинуть страницу?",
    "PreventNavigationDialog.message": "Изменения не были сохранены и будут потеряны при переходе на другой адрес.",
    "PreventNavigationDialog.keep.caption": "Остаться",
    "PreventNavigationDialog.discard.caption": "Отменить изменения",

    "BrandListPage.search.placeholder": "Введите текст для поиска",

    "ShopListPage.search.placeholder": "Введите текст для поиска",

    "UserListPage.search.placeholder": "Введите текст для поиска",
    "UserListPage.addButton.caption": "Добавить",
    "UserListPage.addButton.title": "Добавить нового пользователя",

    "UserRoleEditor.cannawayz:accidents": "Инциденты",
    "UserRoleEditor.cannawayz:accidents:view": "cw:acc:view",
    "UserRoleEditor.cannawayz:accidents:admin": "cw:acc:admin",
    "UserRoleEditor.cannawayz:ads": "Реклама",
    "UserRoleEditor.cannawayz:ads:view": "cw:ads:view",
    "UserRoleEditor.cannawayz:ads:admin": "cw:ads:admin",
    "UserRoleEditor.cannawayz:brands": "Бренды",
    "UserRoleEditor.cannawayz:brands:view": "cw:brands:view",
    "UserRoleEditor.cannawayz:brands:admin": "cw:brands:admin",
    "UserRoleEditor.cannawayz:shops": "Магазины",
    "UserRoleEditor.cannawayz:shops:view": "cw:shops:view",
    "UserRoleEditor.cannawayz:shops:admin": "cw:shops:admin",
    "UserRoleEditor.cannawayz:users": "Пользователи",
    "UserRoleEditor.cannawayz:users:view": "cw:users:view",
    "UserRoleEditor.cannawayz:users:admin": "cw:users:admin",
    "UserRoleEditor.cannawayz:super:admin": "CW:Админ",
    "UserRoleEditor.cannaexchange:super:admin": "CE:Админ",
    "UserRoleEditor.none.caption": "Нет доступа",
    "UserRoleEditor.view.caption": "Только просмотр",
    "UserRoleEditor.admin.caption": "Полный доступ",
    "UserRoleEditor.fullAccess.caption": "Полный доступ ко всем разделам сайта",

    "UserPage.commonGroup.title": "Основное",
    "UserPage.commonGroup.description": "Основная информация о пользователе",

    "UserPage.addressGroup.title": "Адресная информация",
    "UserPage.addressGroup.description":
        "Страна, штат, город, адрес проживания и другая адресная информация о пользователе",

    "UserPage.additionalGroup.title": "Дополнительно",
    "UserPage.additionalGroup.description": "Дополнительная информация",

    "UserPage.adminGroup.title": "Администратор",
    "UserPage.adminGroup.description": "Роли администратора",

    "UserPage.form.username.caption": "Имя пользователя",
    "UserPage.form.username.required": "Необходимо заполнить имя пользователя",
    "UserPage.form.email.caption": "E-mail",
    "UserPage.form.email.required": "Необходимо заполнить e-mail",
    "UserPage.form.email.validate": "Неверный e-mail адрес",
    "UserPage.form.firstName.caption": "Имя",
    "UserPage.form.lastName.caption": "Фамилия",
    "UserPage.form.birthDate.caption": "Дата рождения",
    "UserPage.form.gender.caption": "Пол",
    "UserPage.form.countryCode.caption": "Страна",
    "UserPage.form.regionCode.caption": "Штат/Регион",
    "UserPage.form.city.caption": "Город",
    "UserPage.form.address.caption": "Адрес",
    "UserPage.form.phone.caption": "Телефон",
    "UserPage.form.notificationPhone.caption": "Телефон для оповещений",
    "UserPage.form.timezone.caption": "Временная зона",
    "UserPage.form.locale.caption": "Локаль",
    "UserPage.form.balance.caption": "Баланс",
    "UserPage.form.balance.min": "Баланс не может быть отрицательным",
    "UserPage.form.merchant.caption": "Статус продавца",
    "UserPage.form.blockedTo.caption": "Заблокирован до",
    "UserPage.form.lastLoggedIn.caption": "Последний вход",
    "UserPage.form.admin.caption": "Администратор",

    "UserPage.gender.MALE.caption": "Мужчина",
    "UserPage.gender.FEMALE.caption": "Женщина",
    "UserPage.gender.OTHER.caption": "Дельфин",

    "UserPage.merchant.DISABLED.caption": "Запрещено",
    "UserPage.merchant.NONE.caption": "Нет",
    "UserPage.merchant.WAIT_FOR_SEND.caption": "Ожидает отправки",
    "UserPage.merchant.SENT.caption": "Отправлено",
    "UserPage.merchant.REJECTED.caption": "Отказано",
    "UserPage.merchant.ACCEPTED.caption": "Подтверждено",

    "UserPage.deleteButton.caption": "Удалить",
    "UserPage.deleteButton.title": "Удалить пользователя",
    "UserPage.resetButton.caption": "Сбросить",
    "UserPage.resetButton.title": "Сбросить изменения",
    "UserPage.saveButton.caption": "Сохранить",
    "UserPage.saveButton.title": "Сохранить изменения",
    "UserPage.backButton.caption": "Назад",
    "UserPage.backButton.title": "Назад к пользователям",
    "UserPage.setPasswordButton.caption": "Установить пароль",
    "UserPage.logoutAll.message": "Все сеансы пользователя были завершены",

    "UserPage.deleteConfirm.title": "Удалить пользователя?",
    "UserPage.deleteConfirm.message": "Вы действительно хотите удалить этого пользователя?",
    "UserPage.deleteConfirm.confirmButton.caption": "Удалить",
    "UserPage.deleteConfirm.cancelButton.caption": "Отмена",

    "UserPage.passwordDialog.caption": "Установка пароля",
    "UserPage.passwordDialog.password.caption": "Пароль",
    "UserPage.passwordDialog.okButton.caption": "ОК",
    "UserPage.passwordDialog.cancelButton.caption": "Отмена",
};
export default ru;
