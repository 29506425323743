import React, {useCallback, useState} from "react";
import {
    EuiAvatar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHeaderSectionItemButton,
    EuiHorizontalRule,
    EuiI18n,
    EuiLink,
    EuiPopover,
    EuiText,
    useEuiI18n,
    useGeneratedHtmlId,
} from "@elastic/eui";
import useAppContext from "../providers/AppProvider";

/**
 * User profile menu.
 */
export default function ProfileMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const { user, logout } = useAppContext();

    const toggleMenu = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
    const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
    const doLogout = useCallback(
        (all) => {
            closeMenu();
            logout(all);
        },
        [closeMenu, logout]
    );

    const popoverId = useGeneratedHtmlId({ prefix: "profileMenuPopover" });

    const displayName = user?.username || "Unauthorized";
    let initials = displayName.replaceAll(/[^A-Z]/g, "");
    if (initials.length > 2) initials = initials.substring(0, 2);

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls={popoverId}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Profile menu"
            onClick={toggleMenu}
        >
            <EuiAvatar initials={initials} name={displayName} size="s" />
        </EuiHeaderSectionItemButton>
    );

    return (
        <EuiPopover
            id={popoverId}
            button={button}
            isOpen={isOpen}
            anchorPosition="downRight"
            closePopover={closeMenu}
            panelPaddingSize="m"
        >
            <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup justifyContent="spaceAround">
                        <EuiFlexItem grow={false}>
                            <EuiAvatar initials={initials} name={displayName} size="l" />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiText size="m" className="eui-textCenter">
                        <strong>{displayName}</strong>
                    </EuiText>
                    <EuiText size="s" className="eui-textCenter">
                        {user?.mail}
                    </EuiText>

                    <EuiHorizontalRule size="full" margin="s" />

                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFlexGroup justifyContent="spaceAround">
                                <EuiFlexItem grow={false}>
                                    <EuiLink
                                        onClick={() => doLogout(false)}
                                        title={useEuiI18n("ProfileMenu.logout.title", "Logout")}
                                    >
                                        <EuiI18n token="ProfileMenu.logout.caption" default="Logout" />
                                    </EuiLink>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiLink
                                        onClick={() => doLogout(true)}
                                        title={useEuiI18n("ProfileMenu.logoutAll.title", "Logout from all devices")}
                                    >
                                        <EuiI18n token="ProfileMenu.logoutAll.caption" default="Logout all" />
                                    </EuiLink>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPopover>
    );
}
