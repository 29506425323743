import {useCallback, useState} from "react";

/**
 * useState()-like hook that stores state in localStorage
 * @param key localStorage key
 * @param initial initial value
 */
export default function useLocalStorage(key, initial) {
    key = `cw:${key}`;
    const [value, setValue] = useState(() => {
        let previous = localStorage.getItem(key);
        try {
            if (previous) {
                return JSON.parse(previous);
            }
        } catch (e) {
            /* do nothing */
        }
        return initial;
    });
    const updateStorage = useCallback(
        (value) => {
            setValue(value);
            if (value == null) {
                localStorage.removeItem(key);
            } else {
                localStorage.setItem(key, JSON.stringify(value));
            }
        },
        [key]
    );
    return [value, updateStorage];
}
