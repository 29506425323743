import React from "react";
import ReactDOM from "react-dom/client";

//
//import "@elastic/eui/dist/eui_theme_dark.css";
import "./index.css";

import App from "./App";
import { AppProvider } from "./providers/AppProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AppProvider>
            <App />
        </AppProvider>
    </React.StrictMode>
);
