import { EuiButton, EuiEmptyPrompt, EuiI18n, useEuiI18n } from "@elastic/eui";
import { useNavigate, useRouteError } from "react-router-dom";
import { PageTemplate } from "../components/PageLayout";

/**
 * Error page.
 * @param code error code or text
 * @param title page title
 * @param description description
 * @param header true to show header, false otherwise
 */
export default function ErrorPage({ code, title, description, header = true }) {
    const navigate = useNavigate();
    const defaultCode = useEuiI18n("ErrorPage.code", "Oops");
    const defaultTitle = useEuiI18n("ErrorPage.title", "Something went wrong");
    const defaultDescription = useEuiI18n("ErrorPage.description", "An unexpected error has occurred.");

    let error = useRouteError();
    if (error) {
        console.error(error);
    }

    const prompt = (
        <div style={{ marginTop: 120 }}>
            <EuiEmptyPrompt
                layout="vertical"
                icon={<span style={{ fontWeight: "bold", fontSize: 80 }}>{code || defaultCode}</span>}
                title={<h2>{title || defaultTitle}</h2>}
                body={<p>{description || defaultDescription}</p>}
                actions={[
                    <EuiButton iconType="arrowLeft" fill onClick={() => navigate(-1)}>
                        <EuiI18n token="ErrorPage.backButton.caption" default="Go back" />
                    </EuiButton>,
                ]}
            />
        </div>
    );

    return header ? <PageTemplate>{prompt}</PageTemplate> : prompt;
}
