import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from "@elastic/eui";
import "./PageLoadingIndicator.css";

/**
 * Loading indicator.
 */
export default function PageLoadingIndicator({ marginTop = "120px" }) {
    return (
        <EuiFlexGroup justifyContent="spaceAround" responsive={false} style={{ marginTop }}>
            <EuiFlexItem grow={false} className={"suspense-animation"}>
                <EuiLoadingSpinner size="xxl" />
            </EuiFlexItem>
        </EuiFlexGroup>
    );
}
