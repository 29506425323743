import { createContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import mappings from "../i18n/i18n";
import { EuiContext } from "@elastic/eui";

export const I18nContext = createContext({});

/**
 * i18n support.
 */
export default function I18nProvider({ children }) {
    let navigatorLang = (navigator.language || "en").split("-")[0];
    const [language, setLanguage] = useLocalStorage("lang", navigatorLang);
    const i18n = {
        mapping: mappings[language],
        formatNumber: (value) => new Intl.NumberFormat(language).format(value),
    };
    const value = {
        language,
        setLanguage,
        i18n,
    };
    return (
        <I18nContext.Provider value={value}>
            <EuiContext i18n={i18n}>{children}</EuiContext>
        </I18nContext.Provider>
    );
}
