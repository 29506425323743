import useAppContext from "../providers/AppProvider";
import {
    EuiErrorBoundary,
    EuiHeader,
    EuiHeaderLink,
    EuiHeaderLinks,
    EuiHeaderLogo,
    EuiHeaderSection,
    EuiHeaderSectionItem,
    EuiI18n,
    EuiPage,
} from "@elastic/eui";
import logo from "../logo.svg";
import LanguageSwitcher from "./LanguageSwitcher";
import ColorModeSwitcher from "./ColorModeSwitcher";
import { Suspense, useCallback } from "react";
import PageLoadingIndicator from "./PageLoadingIndicator";
import { Outlet, useLocation, useNavigate } from "react-router";
import routes from "../routes";
import ProfileMenu from "./ProfileMenu";

/**
 * Common page template.
 */
export function PageTemplate({ children }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { hasRole } = useAppContext();

    const makeOnClick = useCallback(
        (to) => (e) => {
            if (e) e.preventDefault();
            navigate(to);
        },
        [navigate]
    );

    return (
        <>
            <EuiHeader position="fixed">
                <EuiHeaderSection>
                    <EuiHeaderSectionItem border="right">
                        <EuiHeaderLogo iconType={logo} href="/" onClick={makeOnClick("/")}>
                            <EuiI18n token="App.caption" default="Cannawayz" />
                        </EuiHeaderLogo>
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>
                <EuiHeaderSection>
                    <EuiHeaderSectionItem border="right">
                        <EuiHeaderLinks>
                            {routes[0].children
                                .filter(({ showInHeader }) => showInHeader)
                                .filter(({roles}) => hasRole(...roles))
                                .map(({ path, caption }) => (
                                    <EuiHeaderLink
                                        key={path}
                                        href={`${process.env.PUBLIC_URL}/${path}`}
                                        isActive={pathname === `/${path}` || pathname.startsWith(`/${path}/`)}
                                        onClick={makeOnClick(path)}>
                                        {caption}
                                    </EuiHeaderLink>
                                ))}
                            <LanguageSwitcher />
                            <ColorModeSwitcher />
                            <ProfileMenu />
                        </EuiHeaderLinks>
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>
            </EuiHeader>
            <EuiPage direction="column" style={{ paddingTop: 48 }}>
                {children}
            </EuiPage>
        </>
    );
}

/**
 * Common page layout.
 */
export default function PageLayout() {
    return (
        <PageTemplate>
            <EuiErrorBoundary>
                <Suspense fallback={<PageLoadingIndicator />}>
                    <Outlet />
                </Suspense>
            </EuiErrorBoundary>
        </PageTemplate>
    );
}
