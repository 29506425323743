export const ROLE_CW_ACCIDENTS_VIEW = "cannawayz:accidents:view";
export const ROLE_CW_ACCIDENTS_ADMIN = "cannawayz:accidents:admin";
export const ROLE_CW_ADS_VIEW = "cannawayz:ads:view";
export const ROLE_CW_ADS_ADMIN = "cannawayz:ads:admin";
export const ROLE_CW_BRANDS_VIEW = "cannawayz:brands:view";
export const ROLE_CW_BRANDS_ADMIN = "cannawayz:brands:admin";
export const ROLE_CW_SHOPS_VIEW = "cannawayz:shops:view";
export const ROLE_CW_SHOPS_ADMIN = "cannawayz:shops:admin";
export const ROLE_CW_USERS_VIEW = "cannawayz:users:view";
export const ROLE_CW_USERS_ADMIN = "cannawayz:users:admin";

export const ROLE_CW_SUPER_ADMIN = "cannawayz:super:admin";
export const ROLE_CE_SUPER_ADMIN = "cannaexchange:super:admin";
