import useLocalStorage from "../hooks/useLocalStorage";
import { decodeJwt } from "jose";
import { createContext, useContext, useState } from "react";
import { ApolloClient, ApolloLink, ApolloProvider, from, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import I18nProvider from "./I18nProvider";
import { ToastProvider } from "./ToastProvider";
import ColorModeProvider from "./ColorModeProvider";

/**
 * Logout mutation.
 */
export const LOGOUT_MUTATION = gql`
    mutation logout($all: Boolean, $userId: Int) {
        logout(all: $all, userId: $userId)
    }
`;

const AppContext = createContext({});

function jwtTokenToUser(token) {
    if (token) {
        const jwt = decodeJwt(token);
        return {
            id: jwt?.user_id,
            mail: jwt?.mail || jwt?.sub,
            username: jwt?.name,
            roles: jwt?.roles,
        };
    }
    return null;
}

/**
 * Color mode, GraphQL-queries, JWT-authentication and refreshing tokens.
 */
export function AppProvider({ children }) {
    const [auth, setAuth] = useLocalStorage("auth");
    const [user, setUser] = useState(jwtTokenToUser(auth?.accessToken));
    const [authenticated, setAuthenticated] = useState(!!auth?.accessToken);

    // State does not always have time to update for the next request,
    // so we store access- and refresh- tokens in separate variables
    let _accessToken = auth?.accessToken;
    let _refreshToken = auth?.refreshToken;
    let _expiresAt = auth?.expiresAt;

    /**
     * Setting up and deleting tokens.
     * @param accessToken access токен
     * @param refreshToken refresh токен
     */
    const setTokens = (accessToken, refreshToken) => {
        if (accessToken) {
            try {
                const jwt = decodeJwt(accessToken);
                const user = jwtTokenToUser(accessToken);

                _accessToken = accessToken;
                _refreshToken = refreshToken;
                _expiresAt = new Date(jwt.exp * 1000);

                setAuth({ accessToken, refreshToken, expiresAt: _expiresAt });
                setUser(user);
                setAuthenticated(true);

                return;
            } catch (error) {
                console.error("Could not decode JWT: ", error);
            }
        }
        _accessToken = _refreshToken = _expiresAt = null;

        setAuth(null);
        setUser(null);
        setAuthenticated(false);
    };

    /*** Apollo client ***/
    const apolloOptions = {
        watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
    };

    const httpLink = new HttpLink({
        uri: `${process.env.PUBLIC_URL}/v2/graphql`,
        credentials: "same-origin",
    });

    /* Authenticate requests */
    const authLink = new ApolloLink((operation, forward) => {
        const accessToken = _accessToken;
        let refreshToken = _refreshToken;
        const expiresAt = _expiresAt;
        if (expiresAt) {
            const diff = new Date(expiresAt) - new Date();
            if (diff > 0) {
                // Do not refresh tokens in advance
                refreshToken = null;
            }
        }
        operation.setContext(({ headers }) => ({
            headers: {
                ...headers,
                authorization: accessToken ? `Bearer ${accessToken}` : "",
                "x-cannaways-refresh-token": refreshToken ? refreshToken : "",
            },
        }));
        return forward(operation);
    });

    /* Refresh tokens */
    const updateTokensLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
            const context = operation.getContext();
            const accessToken = context.response.headers.get("x-cannaways-access-token");
            const refreshToken = context.response.headers.get("x-cannaways-refresh-token");
            if (!!accessToken && !!refreshToken) {
                setTokens(accessToken, refreshToken);
            }
            return response;
        });
    });

    /* Clear authentication if 401 */
    const errorLink = onError((resp) => {
        const { networkError, graphQLErrors } = resp;
        if (
            (networkError && [401].includes(networkError.statusCode)) ||
            graphQLErrors?.[0]?.message?.startsWith("401 Unauthorized")
        ) {
            setTokens(null, null);
        }
    });

    const apolloClient = new ApolloClient({
        link: from([authLink, updateTokensLink, errorLink, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: apolloOptions,
    });

    /* Logout user */
    const logout = (all, userId = null) => {
        const accessToken = auth?.accessToken;
        if (accessToken) {
            return apolloClient
                .mutate({ mutation: LOGOUT_MUTATION, variables: { all: !!all, userId } })
                .catch((e) => console.error("Could not logout", e))
                .finally(() => setTokens(null, null));
        }
        return Promise.resolve();
    };

    /**
     * Test if current user has any of given roles.
     * @param roles one or more roles
     */
    const hasRole = (...roles) => {
        if (roles && user?.roles) {
            for (let i = 0; i < roles.length; i++) {
                if (user.roles.includes(roles[i])) {
                    return true;
                }
            }
        }
        return false;
    };

    const value = {
        authenticated,
        user,
        hasRole,
        setTokens,
        logout,
    };

    return (
        <ApolloProvider client={apolloClient}>
            <AppContext.Provider value={value}>
                <I18nProvider>
                    <ColorModeProvider>
                        <ToastProvider>{children}</ToastProvider>
                    </ColorModeProvider>
                </I18nProvider>
            </AppContext.Provider>
        </ApolloProvider>
    );
}

export default function useAppContext() {
    return useContext(AppContext);
}
