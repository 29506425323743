import {EuiEmptyPrompt, EuiFlexGroup} from "@elastic/eui";

export default function AdsListPage() {
    return (
        <EuiFlexGroup justifyContent="spaceAround" alignItems="center" style={{height: "80vh"}}>
            <EuiEmptyPrompt
                title={<h2>Ads</h2>}
                body={<p>Ads would be here</p>}
            />
        </EuiFlexGroup>
    );
}
