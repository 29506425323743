import { useCallback, useContext, useState } from "react";
import { EuiAvatar, EuiListGroup, EuiListGroupItem, EuiPopover } from "@elastic/eui";
import { I18nContext } from "../providers/I18nProvider";

/**
 * Switch between languages.
 */
export default function LanguageSwitcher() {
    const { language, setLanguage } = useContext(I18nContext);
    const [popoverVisible, setPopoverVisible] = useState(false);

    const closePopover = useCallback(() => setPopoverVisible(false), []);
    const togglePopover = useCallback(() => setPopoverVisible(!popoverVisible), [popoverVisible]);

    const button = (
        <EuiAvatar
            type="space"
            size="s"
            color="plain"
            style={{ cursor: "pointer" }}
            onClick={togglePopover}
            name={language.toUpperCase()}
            initials={language.toUpperCase()}
            initialsLength={2}
        />
    );

    const makeOnClick = useCallback(
        (lang) => () => {
            setLanguage(lang);
            closePopover();
        },
        [setLanguage, closePopover]
    );

    return (
        <EuiPopover button={button} isOpen={popoverVisible} closePopover={closePopover} panelPaddingSize="none">
            <EuiListGroup bordered={true} gutterSize="s">
                <EuiListGroupItem
                    label="English"
                    onClick={makeOnClick("en")}
                    icon={
                        <EuiAvatar
                            type="space"
                            name="English"
                            size="s"
                            color="subdued"
                            initials="EN"
                            initialsLength={2}
                        />
                    }
                />
                <EuiListGroupItem
                    label="Русский"
                    onClick={makeOnClick("ru")}
                    icon={
                        <EuiAvatar
                            type="space"
                            name="Русский язык"
                            size="s"
                            color="subdued"
                            initials="RU"
                            initialsLength={2}
                        />
                    }
                />
            </EuiListGroup>
        </EuiPopover>
    );
}
