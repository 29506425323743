import { useEuiI18n } from "@elastic/eui";
import ErrorPage from "./ErrorPage";

/**
 * 404 error page.
 * @param code error code
 * @param title page title
 * @param description description
 * @param header true to show header, false otherwise
 */
export default function NotFoundErrorPage({ code = 404, title, description, header = true }) {
    const defaultTitle = useEuiI18n("NotFoundErrorPage.title", "Page not found");
    const defaultDescription = useEuiI18n(
        "NotFoundErrorPage.description",
        "The page you are looking for does not exists, have been removed or is temporarily unavailable."
    );
    return (
        <ErrorPage
            code={code}
            title={title || defaultTitle}
            description={description || defaultDescription}
            header={header}
        />
    );
}
