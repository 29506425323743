import React, { useCallback, useContext, useMemo, useState } from "react";
import { EuiGlobalToastList, useEuiI18n } from "@elastic/eui";

const ToastContext = React.createContext({});
let toastId = 0;

/**
 * Global toasts messaging.
 */
export function ToastProvider({ children }) {
    const [toasts, setToasts] = useState([]);

    const informationTitle = useEuiI18n("toasts.info.title", "Information");
    const successTitle = useEuiI18n("toasts.success.title", "Success");
    const warningTitle = useEuiI18n("toasts.warning.title", "Warning");
    const errorTitle = useEuiI18n("toasts.error.title", "Error");

    const showToast = useCallback(
        (text, type, title) => {
            const toast = {
                id: `toast=${toastId++}`,
                color: type,
                title,
                text,
            };
            setToasts((toasts) => [...toasts, toast]);
        },
        [setToasts]
    );

    /**
     * Remove toast.
     */
    const removeToast = useCallback(
        (toast) => {
            setToasts(toasts.filter((t) => t.id !== toast.id));
        },
        [toasts, setToasts]
    );

    /**
     * Remove all toasts.
     */
    const clearToasts = useCallback(() => {
        setToasts([]);
    }, [setToasts]);

    /**
     * Show information toast.
     * @param text message
     * @param title title
     */
    const showInfo = useCallback(
        (text, title) => {
            showToast(text, "primary", title || informationTitle);
        },
        [showToast, informationTitle]
    );

    /**
     * Show success toast.
     * @param text message
     * @param title title
     */
    const showSuccess = useCallback(
        (text, title) => {
            showToast(text, "success", title || successTitle);
        },
        [showToast, successTitle]
    );

    /**
     * Show warning toast.
     * @param text message
     * @param title title
     */
    const showWarning = useCallback(
        (text, title) => {
            showToast(text, "warning", title || warningTitle);
        },
        [showToast, warningTitle]
    );

    /**
     * Show error toast.
     * @param text message
     * @param title title
     */
    const showError = useCallback(
        (text, title) => {
            showToast(text, "danger", title || errorTitle);
        },
        [showToast, errorTitle]
    );

    const value = useMemo(
        () => ({
            showInfo,
            showSuccess,
            showWarning,
            showError,
            clearToasts,
        }),
        [showInfo, showSuccess, showWarning, showError, clearToasts]
    );

    return (
        <ToastContext.Provider value={value}>
            {children}
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                onClearAllToasts={clearToasts}
                toastLifeTimeMs={6000}
            />
        </ToastContext.Provider>
    );
}

export default function useToasts() {
    return useContext(ToastContext);
}
