import { EuiAvatar, useEuiI18n } from "@elastic/eui";
import { useContext } from "react";
import { ColorModeContext } from "../providers/ColorModeProvider";

/**
 * Switch between light and dark modes.
 */
export default function ColorModeSwitcher() {
    const { colorMode, setColorMode } = useContext(ColorModeContext);

    const switchToLightTitle = useEuiI18n("ColorModeSwitcher.light.title", "Switch to light mode");
    const switchToDarkTitle = useEuiI18n("ColorModeSwitcher.dark.title", "Switch to dark mode");

    const iconType = colorMode === "dark" ? "sun" : "moon";
    const title = colorMode === "dark" ? switchToLightTitle : switchToDarkTitle;

    const toggleColorMode = () => setColorMode(colorMode === "dark" ? "light" : "dark");

    return (
        <EuiAvatar
            type="space"
            size="s"
            color="plain"
            iconType={iconType}
            style={{ cursor: "pointer" }}
            onClick={toggleColorMode}
            name={title}
        />
    );
}
