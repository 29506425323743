import { useState } from "react";
import useAppContext from "../../providers/AppProvider";
import { Controller, useForm } from "react-hook-form";
import {
    EuiButton,
    EuiCallOut,
    EuiFieldPassword,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiI18n,
    EuiImage,
    EuiPanel,
    EuiSpacer,
    useEuiI18n,
} from "@elastic/eui";
import logo from "../../logo.svg";
import ColorModeSwitcher from "../../components/ColorModeSwitcher";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { gql, useMutation } from "@apollo/client";

const LOGIN_MUTATION = gql`
    mutation login($email: String!, $password: String!) {
        createAccessToken(email: $email, password: $password, admin: true) {
            token
            refreshToken
        }
    }
`;

/**
 * Login page.
 */
export default function LoginPage() {
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);

    const { setTokens } = useAppContext();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const [doLoginMutation] = useMutation(LOGIN_MUTATION);

    /**
     * Login mutation.
     * @param email user's e-mail
     * @param password user's password
     */
    const doLogin = async ({ email, password }) => {
        try {
            setLoading(true);
            const { data } = await doLoginMutation({
                variables: {
                    email,
                    password,
                },
            });
            const { token: accessToken, refreshToken } = data?.createAccessToken;
            setTokens(accessToken, refreshToken);
        } catch (e) {
            setFormError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <EuiFlexGroup justifyContent="spaceAround" alignItems="center" style={{ height: "100vh" }}>
            <EuiFlexItem grow={false} style={{ maxWidth: 400 }}>
                <EuiPanel style={{ margin: "2em" }}>
                    <EuiFlexGroup direction="row">
                        <EuiFlexItem>
                            <h1>
                                <strong>
                                    <EuiI18n token="LoginPage.caption" default="Log in" />
                                </strong>
                            </h1>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup direction="row" gutterSize="s">
                                <EuiFlexItem grow={false}>
                                    <LanguageSwitcher />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <ColorModeSwitcher />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiSpacer />

                    <EuiFlexGroup justifyContent="spaceAround">
                        <EuiFlexItem grow={false}>
                            <EuiImage src={logo} style={{ blockSize: "120px" }} alt="Logo" />
                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiSpacer />

                    {!!formError && (
                        <>
                            <EuiCallOut iconType="alert" color="danger">
                                <p>{formError}</p>
                            </EuiCallOut>
                            <EuiSpacer />
                        </>
                    )}

                    <EuiForm component="form" onSubmit={handleSubmit(doLogin)}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: useEuiI18n("LoginPage.email.required.message", "E-mail is required") }}
                            render={({ field: { onChange, value } }) => (
                                <EuiFormRow
                                    fullWidth
                                    label={<EuiI18n token="LoginPage.email.caption" default="E-mail" />}
                                    isInvalid={!!errors?.email}
                                    error={errors?.email?.message}>
                                    <EuiFieldText
                                        fullWidth
                                        disabled={loading}
                                        value={value}
                                        onChange={onChange}
                                        isInvalid={!!errors?.email}
                                        error={errors?.email?.message}
                                    />
                                </EuiFormRow>
                            )}
                        />

                        <EuiSpacer />

                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: useEuiI18n("LoginPage.password.required.message", "Password is required"),
                            }}
                            render={({ field: { onChange, value } }) => (
                                <EuiFormRow
                                    fullWidth
                                    label={<EuiI18n token="LoginPage.password.caption" default="Password" />}
                                    isInvalid={!!errors?.password}
                                    error={errors?.password?.message}>
                                    <EuiFieldPassword
                                        fullWidth
                                        type="dual"
                                        disabled={loading}
                                        value={value}
                                        onChange={onChange}
                                        isInvalid={!!errors?.password}
                                        error={errors?.password?.message}
                                    />
                                </EuiFormRow>
                            )}
                        />

                        <EuiSpacer />

                        <EuiFlexGroup justifyContent="spaceAround">
                            <EuiFlexItem grow={false}>
                                <EuiButton fill isLoading={loading} type="submit">
                                    <EuiI18n token="LoginPage.button.caption" default="Log in" />
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiForm>
                </EuiPanel>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
}
